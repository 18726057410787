import React, { useState, FunctionComponent } from "react";

interface CopyClipboardToolProps {
  label: string;
  url: string;
  copiedMessage: string;
}

const CopyClipboardTool: FunctionComponent<CopyClipboardToolProps> = ({
  label,
  url,
  copiedMessage,
}) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          copyToClipboard();
        }}
      >
        {label} {copied && `(${copiedMessage})`}
      </a>
    </div>
  );
};

export default CopyClipboardTool;
